import React, { FC } from "react";
import { BlogPost } from "../../models/blog-post.model";
import Post from "./Post/Post";

interface PostsProps {
	posts: BlogPost[];
}

const Posts: FC<PostsProps> = ({ posts }) => {
	return (
		<div className="grid grid-cols-3 gap-6">
			{posts.map((post, index) => (
				<Post key={index} post={post} />
			))}
		</div>
	);
};

export default Posts;
