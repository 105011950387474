import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { formatBlogPostContent } from "../../helpers/functions";
import { BlogPostStatus } from "../../models/blog-post-status.enum";
import { BlogPost } from "../../models/blog-post.model";
import { GeneratedResult } from "../../models/generated-result.model";
import { AppDispatch, RootState } from "../store";

const firebaseEndpoint = "https://blog-builder-b12bd-default-rtdb.europe-west1.firebasedatabase.app/posts";
const apiEndpoint = "http://localhost:3000";
interface PostsState {
	isAuthenticated: boolean;
	posts: BlogPost[];
	loading: boolean;
	error: string | null;
}

const initialState: PostsState = {
	isAuthenticated: false,
	posts: [],
	loading: false,
	error: null,
};

const postsSlice = createSlice({
	name: "post",
	initialState,
	reducers: {
		tryToAuth(state, action: PayloadAction<string>) {
			state.isAuthenticated = action.payload === "OrtrosAdmin12345";
		},
		postGenerationStart(state) {
			state.loading = true;
			state.error = null;
		},
		postGenerationEnd(state) {
			state.loading = false;
			state.error = null;
		},
		getPostsStart(state) {
			state.loading = true;
			state.error = null;
		},
		getPostsSuccess(state, action: PayloadAction<BlogPost[]>) {
			state.posts = action.payload;
			state.loading = false;
			state.error = null;
		},
		getPostsFailure(state, action: PayloadAction<string>) {
			state.loading = false;
			state.error = action.payload;
		},
		addPostStart(state) {
			state.loading = true;
			state.error = null;
		},
		addPostSuccess(state, action: PayloadAction<BlogPost>) {
			state.posts.push(action.payload);
			state.loading = false;
			state.error = null;
		},
		addPostFailure(state, action: PayloadAction<string>) {
			state.loading = false;
			state.error = action.payload;
		},
		updatePostStart(state) {
			state.loading = true;
			state.error = null;
		},
		updatePostSuccess(state, action: PayloadAction<BlogPost>) {
			const index = state.posts.findIndex((post) => post.uid === action.payload.uid);
			state.posts[index] = action.payload;
			state.loading = false;
			state.error = null;
		},
		updatePostFailure(state, action: PayloadAction<string>) {
			state.loading = false;
			state.error = action.payload;
		},
		deletePostStart(state) {
			state.loading = true;
			state.error = null;
		},
		deletePostSuccess(state, action: PayloadAction<string>) {
			state.posts = state.posts.filter((post) => post.uid !== action.payload);
			state.loading = false;
			state.error = null;
		},
		deletePostFailure(state, action: PayloadAction<string>) {
			state.loading = false;
			state.error = action.payload;
		},
	},
});

// thunks
export const getPosts = () => async (dispatch: AppDispatch) => {
	try {
		dispatch(getPostsStart());
		const response = await fetch(`${firebaseEndpoint}.json`);
		const data = await response.json();
		const posts: BlogPost[] = Object.keys(data).map((key) => ({
			uid: key,
			...data[key],
		}));
		dispatch(getPostsSuccess(posts));

		// to set all as brouillon
		// posts.forEach((post: BlogPost) => {
		// 	fetch(`${firebaseEndpoint}/${post.uid}.json`, {
		// 		method: "PUT",
		// 		headers: { "Content-Type": "application/json" },
		// 		body: JSON.stringify({ ...post, status: BlogPostStatus.DRAFT }),
		// 	});
		// });
	} catch (err: any) {
		dispatch(getPostsFailure(err.toString()));
	}
};

export const generatePostContent = (post: BlogPost) => async (dispatch: AppDispatch) => {
	dispatch(updatePost({ ...post, loading: true }));
	try {
		const response = await fetch(`/generate`, {
			mode: "cors",
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(post),
		});
		response
			.json()
			.then((response: GeneratedResult) => {
				if (response?.choices?.length) {
					const text = formatBlogPostContent(response.choices[0].text);
					dispatch(updatePost({ ...post, loading: false, htmlContent: text }));
				} else {
					console.error("NO RESULT FOR GENERATION");
				}
			})
			.catch((error: any) => {
				console.log("error =>", error);
			});
	} catch (err: any) {
		console.error("ERROR :>> ", err);
	}
};

export const addPost =
	(post: BlogPost, successHandler: (addedPost: BlogPost) => void) => async (dispatch: AppDispatch) => {
		try {
			dispatch(addPostStart());
			const response = await fetch(`${firebaseEndpoint}.json`, {
				method: "POST",
				headers: { "Content-Type": "application/json" },
				body: JSON.stringify(post),
			});
			const uid: string = (await response.json()).name;
			const newPost: BlogPost = { ...post, uid };
			dispatch(addPostSuccess(newPost));
			successHandler(newPost);
		} catch (err: any) {
			dispatch(addPostFailure(err.toString()));
		}
	};

export const updatePost = (post: BlogPost) => async (dispatch: AppDispatch) => {
	try {
		dispatch(updatePostStart());
		const postWithoutUid = { ...post };
		delete postWithoutUid.uid;
		await fetch(`${firebaseEndpoint}/${post.uid}.json`, {
			method: "PUT",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(postWithoutUid),
		});
		dispatch(updatePostSuccess(post));
	} catch (err: any) {
		dispatch(updatePostFailure(err.toString()));
	}
};

export const deletePost = (uid: string) => async (dispatch: AppDispatch) => {
	try {
		dispatch(deletePostStart());
		await fetch(`${firebaseEndpoint}/${uid}.json`, {
			method: "DELETE",
		});
		dispatch(deletePostSuccess(uid));
	} catch (err: any) {
		dispatch(deletePostFailure(err.toString()));
	}
};

// selectors
export const selectPosts = (state: RootState) => state.post.posts;
export const selectPostsLoading = (state: RootState) => state.post.loading;
export const selectPostsError = (state: RootState) => state.post.error;
export const selectIsAuthenticated = (state: RootState) => state.post.isAuthenticated;
export const {
	tryToAuth,
	postGenerationStart,
	postGenerationEnd,
	getPostsStart,
	getPostsSuccess,
	getPostsFailure,
	addPostStart,
	addPostSuccess,
	addPostFailure,
	updatePostStart,
	updatePostSuccess,
	updatePostFailure,
	deletePostStart,
	deletePostSuccess,
	deletePostFailure,
} = postsSlice.actions;

export default postsSlice.reducer;
