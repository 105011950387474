import React, { FC } from "react";
import availableTags from "../../helpers/available-tags";
import Tag from "./Tag/Tag";

interface TagsProps {
	selectedTags: string[];
	showOnlySelected?: boolean;
	toggleSelectedTag?: (tag: string) => void;
}

const Tags: FC<TagsProps> = ({ selectedTags, toggleSelectedTag, showOnlySelected }) => {
	return (
		<div className="flex items-center">
			{availableTags.map(
				(tag, index) =>
					(!showOnlySelected || !!selectedTags?.includes(tag)) && (
						<Tag key={index} selected={!!selectedTags?.includes(tag)} handleClick={() => toggleSelectedTag!(tag)}>
							{tag}
						</Tag>
					)
			)}
		</div>
	);
};

Tags.defaultProps = {
	toggleSelectedTag: () => {},
};

export default Tags;
