import { useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { BlogPost } from "../../models/blog-post.model";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
	addPost,
	generatePostContent,
	getPosts,
	selectPosts,
	selectPostsLoading,
	updatePost,
} from "../../store/slices/postSlice";
import Loading from "../Loading/Loading";
import Tags from "../Tags/Tags";

import { BlogPostStatus } from "../../models/blog-post-status.enum";
import Status from "../Status/Status";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromHTML, ContentState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";

interface PostFormProps {}

const PostForm: FC<PostFormProps> = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { uid } = useParams();
	const posts = useAppSelector(selectPosts);
	const isLoadingPosts = useAppSelector(selectPostsLoading);
	const [currentPost, setCurrentPost] = useState<BlogPost | undefined>(posts.find((post) => post.uid === uid));
	const [displayGeneration, setDisplayGeneration] = useState<boolean>(!currentPost?.htmlContent?.length);
	const [displayContentEdition, setDisplayContentEdition] = useState<boolean>(false);
	const [editorInitDone, setEditorInitDone] = useState<boolean>(false);
	const [showCopied, setShowCopied] = useState<boolean>(false);
	const [editorState, setEditorState] = useState(EditorState.createEmpty());

	useEffect(() => {
		if (!uid?.length) return;

		if (posts?.length) {
			setCurrentPost(posts.find((post) => post.uid === uid));
		} else {
			dispatch(getPosts());
		}
	}, [uid, posts]);

	const { values, errors, touched, resetForm, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
		initialValues: {
			subject: currentPost?.subject || "",
			htmlContent: currentPost?.htmlContent || "",
			minChar: currentPost?.minChar || 0,
			maxChar: currentPost?.maxChar || 0,
			tags: currentPost?.tags || [],
			status: currentPost?.status || BlogPostStatus.DRAFT,
			category: currentPost?.category || "",
			parentCategory: currentPost?.parentCategory || "",
		},
		enableReinitialize: true,
		validationSchema: Yup.object().shape({
			subject: Yup.string().required("Le sujet est requis"),
			minChar: Yup.number().min(0, "Le nombre de caractères minimum est requis"),
			maxChar: Yup.number().min(0, "Le nombre de caractères maximum est requis"),
		}),
		onSubmit: (values: BlogPost) => {
			if (uid) {
				dispatch(updatePost({ ...values, uid }));
			} else {
				dispatch(addPost(values, (post: BlogPost) => navigate(`/edit/${post.uid}`)));
			}
		},
	});

	useEffect(() => {
		if (editorInitDone || !values?.htmlContent?.length) return;
		const htmlContent = convertFromHTML(values?.htmlContent || "");
		const contentState = ContentState.createFromBlockArray(htmlContent.contentBlocks, htmlContent.entityMap);
		setEditorState(EditorState.createWithContent(contentState));
		setEditorInitDone(true);
	}, [values?.htmlContent, editorInitDone]);

	useEffect(() => {
		resetForm();
	}, [currentPost, resetForm]);

	useEffect(() => {
		if (!editorInitDone) return;
		const contentState = editorState.getCurrentContent();
		const html = stateToHTML(contentState);
		setFieldValue("htmlContent", html, false);
	}, [editorState]);

	if (isLoadingPosts) {
		return <Loading />;
	}

	if (!currentPost && !!uid) {
		return <h1>{!!posts.length ? `Article ${uid} introuvable` : `Aucun article trouvé`}</h1>;
	}

	const copyHTMLToClipboard = () => {
		navigator.clipboard.writeText(values?.htmlContent || "").then(() => {
			setShowCopied(true);
			setTimeout(() => setShowCopied(false), 2000);
		});
	};

	const goHome = () => {
		navigate("/");
	};

	const handleGeneration = () => {
		dispatch(generatePostContent({ ...values, uid }));
	};

	const toggleSelectedTag = (tag: string) => {
		if (!values.tags) return;
		const newTags = values.tags.includes(tag) ? values.tags.filter((t) => t !== tag) : [...values.tags, tag];
		handleChange({ target: { name: "tags", value: newTags } });
	};

	const toggleSelectedStatus = (status: BlogPostStatus) => {
		handleChange({ target: { name: "status", value: status } });
	};

	return (
		<div className="p-10">
			<div className="flex items-center justify-between">
				<div className="flex items-center">
					<button onClick={goHome} className="btn btn-ghost fill-white mr-5">
						<svg
							className="w-6 h-6"
							fill="none"
							stroke="currentColor"
							viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M7 16l-4-4m0 0l4-4m-4 4h18" />
						</svg>
					</button>
					<h1>{!!uid ? `Modifier l'article` : `Créer un article à générer`}</h1>
				</div>
				{!!currentPost?.htmlContent?.length && (
					<button onClick={() => setDisplayGeneration((prev) => !prev)} className="btn btn-primary">
						{displayGeneration ? "Masquer la partie génération" : "Afficher la partie génération"}
					</button>
				)}
			</div>
			<form className="mt-8" onSubmit={handleSubmit}>
				<div className="my-6">
					<h3 className="text-2xl font-extrabold text-white mb-3">Status</h3>
					<Status selectedStatus={values.status!} toggleSelectedStatus={toggleSelectedStatus} />
				</div>
				<div className="my-6">
					<h3 className="text-2xl font-extrabold text-white mb-3">Tags</h3>
					<Tags selectedTags={values.tags!} toggleSelectedTag={toggleSelectedTag} />
				</div>
				{displayGeneration && (
					<>
						<div className="label-text text-md text-white font-bold mb-2 ml-1">Sujet de l'article à générer</div>
						<textarea
							rows={4}
							className={`text-lg text-white w-full textarea bg-slate-900 ${
								touched.subject && errors.subject ? "textarea-error" : ""
							}`}
							placeholder="Sujet de l'article"
							value={values.subject}
							name="subject"
							onBlur={handleBlur}
							onChange={handleChange}
						></textarea>
						<div className="grid grid-cols-2 gap-20 mt-6">
							<div>
								<div className="label-text text-md text-white font-bold mb-3 ml-1">
									Taille minimale de l'article : {values.minChar} mot{values.minChar > 1 ? "s" : ""}
								</div>
								<input
									type="range"
									min="0"
									max="2000"
									value={values.minChar}
									onChange={handleChange}
									name="minChar"
									className="range w-full"
									step="100"
								/>
								<div className="w-full flex justify-between text-xs px-2">
									<span>0</span>
									{[...Array(18)].map((_, index) => (
										<span key={index}>|</span>
									))}
									<span>2000</span>
								</div>
							</div>
							<div>
								<div className="label-text text-md text-white font-bold mb-2 ml-1">
									Taille maximale de l'article : {values.maxChar} mot{values.maxChar > 1 ? "s" : ""}
								</div>
								<input
									type="range"
									min="0"
									max="4000"
									value={values.maxChar}
									onChange={handleChange}
									name="maxChar"
									className="range"
									step="200"
								/>
								<div className="w-full flex justify-between text-xs px-2">
									<span>0</span>
									{[...Array(18)].map((_, index) => (
										<span key={index}>|</span>
									))}
									<span>4000</span>
								</div>
							</div>
						</div>
					</>
				)}

				{!!values.htmlContent?.length ? (
					<>
						<div className="flex justify-center mt-12">
							<div className="btn-group flex justify-center mr-8">
								<button
									onClick={() => setDisplayContentEdition(false)}
									type="button"
									className={`btn ${!displayContentEdition ? "btn-active" : ""}`}
								>
									Mode visuel
								</button>
								<button
									onClick={() => setDisplayContentEdition(true)}
									type="button"
									className={`btn ${displayContentEdition ? "btn-active" : ""}`}
								>
									Mode editeur
								</button>
							</div>
							<div
								className={`${showCopied ? "tooltip tooltip-open" : ""} tooltip-success`}
								data-tip="Copié dans le presse-papier"
							>
								<button onClick={() => copyHTMLToClipboard()} type="button" className="btn btn-warning">
									Copier l'HTML
								</button>
							</div>
						</div>

						{!displayContentEdition ? (
							<div className="w-full flex justify-center flex-col">
								<h2 className="text-center mb-5 mt-10 text-2xl font-extrabold dark:text-white">
									Contenu de l'article généré
								</h2>
								<div className="mb-10 mockup-code bg-base-300 w-90">
									<div
										className="htmlInterpreter flex flex-col px-10 py-6"
										dangerouslySetInnerHTML={{ __html: values.htmlContent }}
									></div>
								</div>

								<div className="flex items-center gap-2 justify-center">
									<button
										type="button"
										className="btn btn-info gap-2"
										onClick={() => setDisplayContentEdition((prev) => !prev)}
									>
										{displayContentEdition ? "Masquer" : "Afficher"} le mode édition
									</button>
									<button
										type="button"
										onClick={handleGeneration}
										disabled={values.subject.length < 10 || values.minChar < 0 || values.maxChar <= 0}
										className="btn gap-2"
									>
										<svg
											className="w-6 h-6"
											fill="none"
											stroke="currentColor"
											stroke-width="1.5"
											viewBox="0 0 24 24"
											xmlns="http://www.w3.org/2000/svg"
											aria-hidden="true"
										>
											<path
												stroke-linecap="round"
												stroke-linejoin="round"
												d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
											></path>
										</svg>
										Écraser et générer à nouveau
									</button>
								</div>
							</div>
						) : (
							<div className="mt-10">
								<Editor
									editorState={editorState}
									toolbarClassName="editor-toolbar"
									editorClassName="rounded-none text-lg text-white w-full textarea bg-base-300"
									onEditorStateChange={setEditorState}
								/>
							</div>
						)}
					</>
				) : (
					<button
						type="button"
						onClick={handleGeneration}
						disabled={values.subject.length < 10 || values.minChar < 0 || values.maxChar <= 0}
						className="btn btn-primary mx-auto my-10 gap-2"
					>
						<svg
							className="w-6 h-6"
							fill="none"
							stroke="currentColor"
							viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth={2}
								d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z"
							/>
						</svg>
						Générer le contenu de l'article
					</button>
					// filter : brightness(0) invert(1);
				)}

				<div className="flex justify-end">
					<button type="submit" className="btn btn-primary mt-6 gap-2">
						{!!uid ? `Modifier l'article` : `Créer la base de l'article`}
						<svg
							className="w-6 h-6"
							fill="none"
							stroke="currentColor"
							viewBox="0 0 24 24"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
						</svg>
					</button>
				</div>
			</form>
		</div>
	);
};

export default PostForm;
