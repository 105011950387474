import React, { FC, useState } from "react";
import { useAppDispatch } from "../../store/hooks";
import { tryToAuth } from "../../store/slices/postSlice";

interface LoginProps {}

const Login: FC<LoginProps> = () => {
	const [password, setPassword] = useState<string>("");

	const dispatch = useAppDispatch();

	const onLogin = () => {
		dispatch(tryToAuth(password));
	};

	return (
		<div className="min-h-screen bg-slate-800 py-6 flex flex-col justify-center sm:py-12">
			<div className="relative py-3 sm:max-w-xl sm:mx-auto">
				<div className="absolute inset-0 bg-gradient-to-r from-blue-300 to-blue-600 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl"></div>
				<div className="relative px-4 py-10 bg-base-300 shadow-lg sm:rounded-3xl sm:p-20">
					<div className="max-w-md mx-auto">
						<div>
							<h1 className="text-4xl text-white font-semibold">Blog builder</h1>
						</div>
						<div className="divide-y divide-gray-200  radius-md">
							<div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7 radius-md">
								<div className="relative radius-md">
									<label
										htmlFor="password"
										className=" text-gray-200 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-5 peer-focus:text-gray-200 peer-focus:text-sm"
									>
										Password
									</label>
									<input
										id="password"
										name="password"
										type="password"
										value={password}
										onChange={(e) => setPassword(e.target.value)}
										className="radius-sm text-center peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-white focus:outline-none"
										placeholder="Password"
									/>
								</div>
								<div className="relative flex justify-end">
									<button onClick={onLogin} className="btn btn-ghost text-white text-bold w-24 rounded-md px-2 py-1">
										Submit
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Login;
