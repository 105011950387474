import React, { FC } from "react";
import { BlogPostStatus } from "../../models/blog-post-status.enum";

interface StatusProps {
	selectedStatus: BlogPostStatus;
	toggleSelectedStatus: (status: BlogPostStatus) => void;
}

const Status: FC<StatusProps> = (props) => {
	const availableStatus = Object.entries(BlogPostStatus).map(([key, value]) => ({
		key,
		value,
	}));

	const getBlogPostClass = (status: BlogPostStatus): string => {
		switch (status) {
			case BlogPostStatus.READY_TO_GENERATE:
				return "btn btn-error";
			case BlogPostStatus.READY_TO_REVIEW:
				return "btn btn-warning";
			case BlogPostStatus.READY_TO_PUBLISH:
				return "btn btn-info";
			case BlogPostStatus.PUBLISHED:
				return "btn btn-success";
			default:
				return "btn btn-primary";
		}
	};

	return (
		<div className="flex items-center justify-start">
			<div className="btn-group">
				{availableStatus.map((status) => (
					<button
						type="button"
						onClick={() => props.toggleSelectedStatus(status.value)}
						className={`btn ${status.value === props.selectedStatus ? "" : "btn-outline"} ${getBlogPostClass(
							status.value
						)}`}
					>
						{status.value}
					</button>
				))}
			</div>
		</div>
	);
};

export default Status;
