import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BlogPostStatus } from "../../models/blog-post-status.enum";
import { BlogPost } from "../../models/blog-post.model";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { generatePostContent, getPosts, selectPosts, selectPostsLoading } from "../../store/slices/postSlice";
import Loading from "../Loading/Loading";
import Posts from "../Posts/Posts";

interface BlogPostsProps {}

const BlogPosts: FC<BlogPostsProps> = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const isLoadingPosts: boolean = useAppSelector(selectPostsLoading);
	const posts: BlogPost[] = useAppSelector(selectPosts);
	const [filters, setFilters] = useState<BlogPostStatus[]>([]);
	const [filteredPosts, setFilteredPosts] = useState<BlogPost[]>(posts);

	const filterPosts = () => {
		if (!filters.length) {
			setFilteredPosts(posts);
		} else {
			setFilteredPosts(posts.filter((post) => !!filters?.includes(post.status!)));
		}
	};

	useEffect(() => {
		dispatch(getPosts());
	}, [dispatch]);

	useEffect(() => {
		filterPosts();
	}, [filters, posts]);

	const goToAddPost = () => {
		navigate("/add");
	};

	const toggleFilter = (filter: BlogPostStatus) => {
		if (filters.includes(filter)) {
			setFilters(filters.filter((f) => f !== filter));
		} else {
			setFilters([...filters, filter]);
		}
	};

	const generateAllPosts = async () => {
		for (const post of posts) {
			dispatch(generatePostContent(post));
			await new Promise((resolve) => setTimeout(resolve, 20000));
		}
	};

	return (
		<div className="bg-base-300 w-full h-full p-10">
			<div className="mb-10 flex items-center justify-between">
				<h1 className="text-4xl font-extrabold leading-none tracking-tight md:text-5xl lg:text-6xl text-white">
					Blog Builder
				</h1>
				{/* <button className="btn btn-primary gap-2" onClick={generateAllPosts}>
					Lancer la génération de tous les articles
					<svg
						className="w-6 h-6"
						fill="none"
						stroke="currentColor"
						stroke-width="1.5"
						viewBox="0 0 24 24"
						xmlns="http://www.w3.org/2000/svg"
						aria-hidden="true"
					>
						<path
							stroke-linecap="round"
							stroke-linejoin="round"
							d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125"
						></path>
					</svg>
				</button> */}
				<button className="btn btn-warning gap-2" onClick={goToAddPost}>
					Créer un article
					<svg
						className="w-6 h-6"
						fill="none"
						stroke="currentColor"
						viewBox="0 0 24 24"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
					</svg>
				</button>
			</div>
			<div className="flex items-center mb-10">
				{Object.values(BlogPostStatus).map((status, index) => (
					<button
						key={index}
						className={`btn mr-3 ${filters.includes(status) ? "btn-info" : "btn-ghost"}`}
						onClick={() => toggleFilter(status)}
					>
						{status}
					</button>
				))}
			</div>
			{isLoadingPosts ? <Loading /> : <Posts posts={filteredPosts} />}
		</div>
	);
};

export default BlogPosts;
