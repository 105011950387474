import React, { FC } from 'react';


interface BadgePublishedProps {}

const BadgePublished: FC<BadgePublishedProps> = () => (
	<span className="indicator-item badge badge-warning h-9 w-9">
		<svg fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
			<path
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={2}
				d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4"
			/>
		</svg>
	</span>
);

export default BadgePublished;
