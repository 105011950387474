import React, { FC } from 'react';


interface BadgeRtpProps {}

const BadgeRtp: FC<BadgeRtpProps> = () => (
	<span className="indicator-item badge badge-success h-9 w-9">
		<svg fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
			<path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
		</svg>
	</span>
);

export default BadgeRtp;
