import React, { FC } from "react";

interface TagProps {
	selected?: boolean;
	children: React.ReactNode;
  handleClick?: () => void;
}

const Tag: FC<TagProps> = ({ selected, children, handleClick }) => {
	return (
		<div
			onClick={handleClick}
			className={`${
				selected ? "bg-primary scale-105 " : "border-2 border-primary"
			} mr-4 transition-all duration-200 ease-in-out hover:scale-105 hover:bg-primary cursor-pointer text-xs inline-block py-2 px-2.5 leading-none text-center whitespace-nowrap align-baseline font-bold text-white rounded`}
		>
			{children}
		</div>
	);
};

export default Tag;
