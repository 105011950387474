const availableTags: string[] = [
	"Zoom 🔎",
	"Tool Box 🧰",
	"Top Ten 🏆",
	"Best Practices 📝",
	"How To 📚",
	"(Dis)Advantages 📈 / 📉",
];

export default availableTags;
