import React, { FC } from "react";
import { BlogPost } from "../../../models/blog-post.model";
import { useNavigate } from "react-router-dom";
import { BlogPostStatus } from "../../../models/blog-post-status.enum";
import BadgeDraft from "../../Badges/BadgeDraft/BadgeDraft";
import BadgeRtg from "../../Badges/BadgeRTG/BadgeRTG";
import BadgeRtp from "../../Badges/BadgeRTP/BadgeRTP";
import BadgeRtr from "../../Badges/BadgeRTR/BadgeRTR";
import BadgePublished from "../../Badges/BadgePublished/BadgePublished";
import { useAppDispatch } from "../../../store/hooks";
import { deletePost, generatePostContent } from "../../../store/slices/postSlice";
import Tags from "../../Tags/Tags";
import Loading from "../../Loading/Loading";

interface PostProps {
	post: BlogPost;
}

const Post: FC<PostProps> = ({ post }) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const handlePostClick = () => {
		navigate(`/edit/${post.uid}`);
	};

	const getPostStatus = () => {
		switch (post.status) {
			case BlogPostStatus.READY_TO_GENERATE:
				return <BadgeRtg />;
			case BlogPostStatus.READY_TO_REVIEW:
				return <BadgeRtr />;
			case BlogPostStatus.READY_TO_PUBLISH:
				return <BadgeRtp />;
			case BlogPostStatus.PUBLISHED:
				return <BadgePublished />;
			default:
				return <BadgeDraft />;
		}
	};

	const handleDelete = async () => {
		if (!window.confirm("Voulez-vous vraiment supprimer cet article ?")) {
			return;
		}

		dispatch(deletePost(post.uid!));
	};

	const handleGeneration = () => {
		dispatch(generatePostContent(post));
	};

	return (
		<div className="indicator w-auto">
			{getPostStatus()}
			<div className="card w-full bg-base-100 shadow-xl">
				{post.loading ? (
					<div className="flex flex-col items-center">
						<Loading />
						<p className="mt-5 text-white font-bold">Génération du contenu...</p>
					</div>
				) : (
					<div className="card-body">
						<div className="h-full">
							<h2 className="card-title">{post.subject}</h2>
							{post.htmlContent?.length ? (
								<p className="truncate text-ellipsis overflow-hidden">{post.htmlContent}</p>
							) : (
								"TODO"
							)}
						</div>
						<Tags showOnlySelected={true} selectedTags={post.tags ?? []} />
						<div className="card-actions flex justify-end">
							<button
								onClick={handleDelete}
								type="button"
								className="btn btn-ghost text-white focus:outline-none font-medium rounded-lg text-sm p-3 text-center inline-flex items-center "
							>
								<svg
									className="w-6 h-6"
									fill="none"
									stroke="currentColor"
									viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth={2}
										d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
									/>
								</svg>
								<span className="sr-only">Icon description</span>
							</button>
							<button
								onClick={handlePostClick}
								type="button"
								className="btn btn-secondary focus:outline-none font-medium rounded-lg text-sm p-3 text-center inline-flex items-center "
							>
								<svg
									className="w-6 h-6"
									fill="none"
									stroke="currentColor"
									viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth={2}
										d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
									/>
								</svg>
								<span className="sr-only">Icon description</span>
							</button>
							<button onClick={handleGeneration} className="btn btn-primary">
								Générer l'article
							</button>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default Post;
