import { Route, Routes } from "react-router-dom";
import BlogPosts from "./components/BlogPosts/BlogPosts";
import Login from "./components/Login/Login";
import PostForm from "./components/PostForm/PostForm";
import { useAppSelector } from "./store/hooks";
import { selectIsAuthenticated } from "./store/slices/postSlice";

const App = () => {
	const isAuth = useAppSelector(selectIsAuthenticated);
	return isAuth ? (
		<Routes>
			<Route path="/" element={<BlogPosts />} />
			<Route path="/edit/:uid" element={<PostForm />} />
			<Route path="/add" element={<PostForm />} />
		</Routes>
	) : (
		<Routes>
			<Route path="*" element={<Login />} />
		</Routes>
	);
};

export default App;
